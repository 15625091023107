import request from '../utils/request'
import config from './config'

/**
 * 工单管理
 */

// 工单列表
export function getWorkOrderPage(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionHksWorkOrder/getPensionHksWorkOrderPage`,
		method: 'post',
		data,
	})
}

// 工单统计
export function getCount(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionHksWorkOrder/getPensionHksWorkOrderCount`,
		method: 'get',
		params,
	})
}

// 服务人员
export function hksStaffList(params) {
	return request({
		url: `${config.CIVIL_HOST}/civilCount/hksStaffList`,
		method: 'get',
		params,
	})
}

// 工单详情
export function getWorkOrderDetails(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionHksWorkOrder/getWorkOrderDetails`,
		method: 'get',
		params,
	})
}

// 工单跟踪详情
export function getWorkOrderAfter(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionHksWorkOrder/getWorkOrderAfter`,
		method: 'get',
		params,
	})
}

// 导出工单列表
export function WorkOrderPageDown(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionHksWorkOrder/WorkOrderPageDown`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 床位获取服务记录
export function getBedWorkOrderList(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionHksWorkOrder/getBedWorkOrderList`,
		method: 'post',
		data,
	})
}
