<template>
	<div class="buildTrack">
		<div class="mask" v-show="loading">
			<span style="position: relative; top: 420px; left: 466px; color: #909399">数据加载中</span>
			<div class="dot-pulse"></div>
		</div>
		<el-row>
			<el-col :span="4" class="title">订单进度：</el-col>
			<el-col :span="20">
				<el-steps :active="active" style="width: 98%">
					<el-step title="生成工单" :description="order.createTime || ''" />
					<el-step title="确认工单" :description="order.confirmTime || ''" />
					<el-step title="分配工单" :description="order.distTime || ''" />
					<el-step title="开始服务" :description="order.serviceStartTime || ''" />
					<el-step title="结束服务" :description="order.serviceEntTime || ''" />
					<el-step title="完成评价" :description="order.rateTime || ''" />
				</el-steps>
			</el-col>
		</el-row>
		<el-row v-if="order.serviceFrontImgs && order.serviceFrontImgs.length > 0">
			<el-col :span="4">服务前：</el-col>
			<el-col :span="6">
				<el-image
					v-for="(img, index) in order.serviceFrontImgs"
					:key="index"
					style="width: 200px; margin-right: 20px"
					:src="img"
					:preview-src-list="[img]"
				>
					<div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
				</el-image>
			</el-col>
			<el-col :span="2">签到位置：</el-col>
			<el-col :span="8">
				<Complete :lnglats="positionA" :homeLngLats="homePosition" />
				<div style="text-align: center; color: #f56c6c; padding-top: 10px; padding-right: 50px">
					<span v-if="preException !== ''">{{ preException }}</span>
				</div>
			</el-col>
		</el-row>
		<el-row v-if="order.serviceBackImgs && order.serviceBackImgs.length > 0">
			<el-col :span="4">服务后：</el-col>
			<el-col :span="6">
				<el-image
					v-for="(img, index) in order.serviceBackImgs"
					:key="index"
					style="width: 200px; margin-right: 20px"
					:src="img"
					:preview-src-list="[img]"
				>
					<div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
				</el-image>
			</el-col>
			<el-col :span="2">签退位置：</el-col>
			<el-col :span="8">
				<Refuse :lnglats="positionB" :homeLngLats="homePosition" />
				<div style="text-align: center; color: #f56c6c; padding-top: 10px; padding-right: 50px">
					<span v-if="aftException !== ''">{{ aftException }}</span>
				</div>
			</el-col>
		</el-row>
		<el-row v-if="value">
			<el-col :span="4">订单评分：</el-col>
			<el-col :span="20">
				<el-rate v-model="value" disabled show-score text-color="#ff9900" score-template="{value}" />
			</el-col>
		</el-row>
		<el-row v-if="rateMessage">
			<el-col :span="4">订单评价：</el-col>
			<el-col :span="20">{{ rateMessage ? rateMessage : '暂无评价' }}</el-col>
		</el-row>
	</div>
</template>

<script>
import Complete from './complete.vue'
import Refuse from './refuse.vue'
import { getWorkOrderAfter } from '@/api/workManage'
export default {
	// 建设跟踪
	name: 'buildTrack',
	components: {
		Complete,
		Refuse,
	},
	props: {
		orderId: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			lnglats: {},
			active: 1, // 步骤条进度
			value: 0, // 平分
			order: {}, // 订单信息
			loading: true, // 加载
			positionA: [],
			positionB: [],
			homePosition: [],
			rateMessage: '',
			preException: '',
			aftException: '',
		}
	},
	mounted() {
		this.getInfo()
	},
	methods: {
		// 获取订单信息
		async getInfo() {
			const res = await getWorkOrderAfter({
				workOrderId: this.orderId,
			})
			this.order = res.data
			this.value = this.order.rateMark ? Number(this.order.rateMark) : 0
			this.loading = false
			this.positionA = [res.data.lonA, res.data.latA]
			this.positionB = [res.data.lonB, res.data.latB]
			this.homePosition = [res.data.homeLon, res.data.homeLat]
			this.rateMessage = res.data.rateMessage
			if (res.data.preServiceException === 1) {
				console.log(11111111)
				this.preException = '签到异常'
			}
			if (res.data.aftServiceException === 1) {
				this.aftException = '签退异常'
			}
			this.active = res.data.stepType
		},
	},
}
</script>

<style lang="scss" scoped>
@keyframes dotPulse {
	0% {
		box-shadow: 9984px 0 0 -5px #67c23a, 9999px 0 0 0 #67c23a, 10014px 0 0 2px #67c23a;
	}
	25% {
		box-shadow: 9984px 0 0 0 #67c23a, 9999px 0 0 2px #67c23a, 10014px 0 0 0 #67c23a;
	}
	50% {
		box-shadow: 9984px 0 0 2px #67c23a, 9999px 0 0 0 #67c23a, 10014px 0 0 -5px #67c23a;
	}
	75% {
		box-shadow: 9984px 0 0 0 #67c23a, 9999px 0 0 -5px #67c23a, 10014px 0 0 0 #67c23a;
	}
	100% {
		box-shadow: 9984px 0 0 -5px #67c23a, 9999px 0 0 0 #67c23a, 10014px 0 0 2px #67c23a;
	}
}
.buildTrack {
	height: 100%;
	overflow-y: auto;
	// max-height: 700px;
	.mask {
		height: 834px;
		.dot-pulse {
			position: relative;
			top: 378px;
			left: -9500px;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: #67c23a;
			color: #67c23a;
			box-shadow: 9984px 0 0 0 #67c23a, 9999px 0 0 0 #67c23a, 10014px 0 0 0 #67c23a;
			animation: dotPulse 1.5s infinite linear;
		}
	}
	.myMap {
		width: 85%;
		height: 300px;
	}
}
.el-col-4 {
	text-align: right;
}
[class*='el-col-'] {
	margin: 16px 0;
}
img {
	width: 200px;
	margin-right: 20px;
}
.image-slot {
	width: 200px;
	height: 200px;
	background: #f5f5f5;
	text-align: center;
	line-height: 200px;
	color: gray;
}
</style>
